










import Vue from 'vue';

import NavBarComponent from './components/NavBarComponent.vue';

export default Vue.extend({
  name: 'App',
  components: {
    NavBarComponent
  }
});
