import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import { Routes } from '../config'

import HomePage from '@/client/views/HomePage.vue'
import EventPage from '@/client/views/EventPage.vue'

Vue.use(VueRouter)


const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: Routes.Home,
        component: HomePage,
    },
    {
        path: '/event/:id',
        name: Routes.Event,
        component: EventPage,
    },
    {
      path: "*",
      component: HomePage
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
