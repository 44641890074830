




























































import Vue from 'vue'
import dayjs from 'dayjs'
import { getEvent } from '@/client/firebase/orm';
import { LiveEvent } from '@/shared/types';
import LivePlayerComponent from '@/shared/components/LivePlayerComponent.vue';
import ChatComponent from '@/client/components/ChatComponent.vue';
import {
  SHOW_PLAYER_MINUTES_AFTER,
  SHOW_PLAYER_MINUTES_BEFORE
} from '@/shared/config';

declare type EventStatus = 'before' | 'during' | 'after'


interface Data {
    loading: boolean,

    event_status: EventStatus
    event: LiveEvent | null
}

export default Vue.extend({
    name: 'PublicEvent',
    components: {
      ChatComponent,
      LivePlayerComponent
    },
    data: (): Data => ({
        loading: true,
        
        event_status: 'before',
        event: null,
    }),
    computed: {
        hoursToLive() {

            if(this.event) {
                return dayjs(this.event.start_at).diff(dayjs(), 'hour', true)
            }

            return null
        },
    },
    methods: {
        async initEvent(id: string) {
            // reset
            this.loading = true

            this.event = null

            try {
                this.event = await getEvent(id)

                // calculate event status
                if(dayjs().isBefore(this.event.start_at)) {
                  // can start
                  const minutesToLive = dayjs(this.event.start_at).diff(dayjs(), 'minute', true)
                  console.log('minutesToLive')
                  console.log(minutesToLive)
                  this.event_status = minutesToLive > SHOW_PLAYER_MINUTES_BEFORE ? 'before' : 'during'

                } else {
                  const minutesAfterLiveStart = dayjs().diff(dayjs(this.event.start_at), 'minute', true)
                  this.event_status = minutesAfterLiveStart < SHOW_PLAYER_MINUTES_AFTER ? 'during' : 'after'
                }

            } catch(e) {
                console.log(e)
            }

            this.loading = false
        },
    },
    beforeRouteEnter(to, from, next) {
        next((vm: any) => {
            vm.initEvent(to.params.id)
        })
    },
    beforeRouteUpdate(to, from, next) {
        next((vm: any) => {
            vm.initEvent(to.params.id)
        })
    },
})

