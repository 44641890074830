import firebase from 'firebase'

// These imports load individual services into the firebase namespace.
import 'firebase/firestore'


function loadApp(): Promise<firebase.app.App> {

    const appPromise: Promise<firebase.app.App> = new Promise(function(resolve, reject) {

        try {

            fetch('/__/firebase/init.json').then(async response => {
                const json = await response.json()
                const app = firebase.initializeApp(json)

                if(process.env.NODE_ENV === 'development') {
                    console.log('app dev settings')
                    firebase.firestore().settings({
                        host: "localhost:5002",
                        ssl: false
                    })
                    firebase.auth().useEmulator('http://localhost:9099/');
                }

                // console.log('app created')
                // console.log(json)
                // console.log(app)
                resolve(app)
            });

        } catch (e) {
            // console.log(e)
            reject()
        }

    })

    return appPromise
}

const appPromise: Promise<firebase.app.App> = loadApp()

export async function getApp(): Promise<firebase.app.App> {
    return appPromise
}