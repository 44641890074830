







import LivePlayerVideoJSComponent from "@/shared/components/LivePlayerVideoJSComponent.vue";
import { LIVESTREAM_URL } from "@/shared/config";

interface Data {
  LIVESTREAM_URL: string
}

export default {
  name: 'LivePlayerComponent',
  components: {LivePlayerVideoJSComponent},
  props: ['src'],
  data: () => ({
    LIVESTREAM_URL,
  }),
}
