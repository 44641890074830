import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false

import 'video.js/dist/video.min'
import 'video.js/dist/video-js.min.css'

import dayjs from 'dayjs'

// add filter
Vue.filter('dayjs', (value: Date, format: string) => {
  const d = dayjs(value);

  if (!d.isValid()) {
    console.error('Could not parse into date: ' + value)
    return value
  }

  return d.format(format)
})

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
