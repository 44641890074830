import firebase from 'firebase'
import 'firebase/firestore'

import {
    LiveEvent,
    LiveEventFirebase,
    Message,
    MessageFirebase
} from '@/shared/types';
import {
    COLLECTION_EVENTS,
    COLLECTION_EVENTS_MESSAGES
} from '@/shared/config';
import QuerySnapshot = firebase.firestore.QuerySnapshot;
import DocumentData = firebase.firestore.DocumentData;
import Unsubscribe = firebase.Unsubscribe;
import { getApp } from '@/client/firebase/index';
import Vue from 'vue';


export async function getEvent(id: string): Promise<LiveEvent> {

    await getApp()

    const liveEventDoc = await firebase.firestore()
        .collection(COLLECTION_EVENTS)
        .doc(id)
        .get()

    const liveEventData = liveEventDoc.data() as LiveEventFirebase

    const liveEvent: LiveEvent = {
        ...liveEventData,
        id: liveEventDoc.id,
        start_at: liveEventData.start_at.toDate()
    }

    return liveEvent
}

// MESSAGES

export async function createMessage(message: Message, event: LiveEvent) {

    const m: MessageFirebase = {
        ...message,
        created_at: firebase.firestore.Timestamp.fromDate(message.created_at)
    }

    await firebase.firestore()
        .collection(COLLECTION_EVENTS).doc(event.id)
        .collection(COLLECTION_EVENTS_MESSAGES)
        .add(m)
}

// export async function onMessages(event: LiveEvent, callback: (snapshot: QuerySnapshot<DocumentData>) => void): Promise<Unsubscribe> {
//
//     return firebase.firestore()
//         .collection(COLLECTION_EVENTS).doc(event.id)
//         .collection(COLLECTION_EVENTS_MESSAGES)
//         .orderBy('created_at', 'asc')
//         .onSnapshot(callback)
//
// }

export async function onNewMessage(event: LiveEvent, handleNewMessage: (message: Message) => void): Promise<Unsubscribe> {

    return firebase.firestore()
        .collection(COLLECTION_EVENTS).doc(event.id)
        .collection(COLLECTION_EVENTS_MESSAGES)
        .orderBy('created_at', 'asc')
        .onSnapshot(snapshot => {

            // add new messages
            snapshot.docChanges().forEach((change) => {

                if (change.type === 'added') {
                    const messageData = change.doc.data() as MessageFirebase

                    const m: Message = {
                        ...messageData,
                        id: change.doc.id,
                        created_at: messageData.created_at.toDate()
                    }

                    handleNewMessage(m)
                }
            })

        })



}