<template>
    <video ref="videoPlayer" class="video-js vjs-big-play-centered vjs-fill" ></video>
</template>

<script>
    // TODO TSX
    import videojs from 'video.js'
    // TODO check https://docs.videojs.com/tutorial-layout.html

    export default {
        name: 'LivePlayerVideoJSComponent',
        props: ['src'],
        data () {
            return {
                player: null,
            }
        },
        mounted() {
          const options = {
              controls: true,
              autoplay: true,
              errorDisplay: false,
              disablePictureInPicture: true,
              sources: [
                {
                  src: this.src,
                  type: 'application/x-mpegURL'
                }
              ]
          }

          this.player = videojs(this.$refs.videoPlayer, options)

            // https://docs.videojs.com/docs/api/player.html#Eventserror
          this.player.on('ready', function() {
              console.log('Player ready');
          });
          this.player.on('playing', function() {
              console.log('Player playing');
          });
          // this.player.on('timeupdate', function(e) {
          //     console.log('Player timeupdate');
          // });

          // keep reloading until source is found
          this.player.on('error', () => {
              console.log('Error - reset source');

              setTimeout(() => {
                  this.player.src(
                      {
                          src: this.src,
                          type: 'application/x-mpegURL'
                      }
                  )
              }, 1000)
          });

        },
        beforeDestroy() {
          if (this.player) {
            this.player.dispose()
          }
        },
    }
</script>

<style scoped>
    .video-js {
        background: gray;
    }
</style>

